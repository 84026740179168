import React from "react";
import SharedAnalysisDisplay, { ChunkContent } from "./SharedAnalysisDisplay";

const DiagnosisFnDisplay = ({ content, pdfUrl, pagesPerChunk }) => {
  const renderContent = (condition, data, index) => {
    if (index === undefined) {
      // Render summary content
      return (
        <p className="text-gray-700">
          Evidence for {condition} was found in {data.chunk_ids.length} section(s)
          of the record.
        </p>
      );
    }

    // Render chunk-specific content
    return (
      <ChunkContent>
        <h4 className="font-medium text-gray-700 mb-2">Section {index + 1}</h4>
        <h5 className="font-medium text-gray-700 mb-2">Explanation:</h5>
        {data.explanations?.[index] ? (
          <blockquote className="pl-4 border-l-4 border-green-500 italic text-gray-600">
            "{data.explanations[index]}"
          </blockquote>
        ) : (
          <p className="text-gray-500">
            No specific explanation provided for this item.
          </p>
        )}
      </ChunkContent>
    );
  };

  return (
    <SharedAnalysisDisplay
      content={content}
      pdfUrl={pdfUrl}
      pagesPerChunk={pagesPerChunk}
      alertClassName="bg-green-50 border border-green-200"
      titleClassName="text-green-800"
      renderContent={renderContent}
    />
  );
};

export default DiagnosisFnDisplay;
