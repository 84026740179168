import React, { useState, useEffect, useRef, useCallback } from "react";
import { Document, Page } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import { Download } from "lucide-react";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const PDFRenderer = ({ url, pageNumbers, searchTerm, alertTerms }) => {
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const [pageWidth, setPageWidth] = useState(600);
  const containerRef = useRef(null);

  useEffect(() => {
    const updatePageWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setPageWidth(Math.min(containerWidth - 32, 600)); // 32px for padding
      }
    };

    updatePageWidth();
    window.addEventListener("resize", updatePageWidth);

    return () => window.removeEventListener("resize", updatePageWidth);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    console.error("Error loading PDF:", error);
    setError(error);
  }

  const highlightTerms = useCallback(() => {
    if (!searchTerm && !alertTerms) return;

    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    
    textLayers.forEach((textLayer) => {
      const textItems = textLayer.querySelectorAll('span');
      
      textItems.forEach((span) => {
        const text = span.textContent;
        let newHTML = text;

        if (searchTerm) {
          const searchRegex = new RegExp(searchTerm, 'gi');
          newHTML = newHTML.replace(searchRegex, match => `<mark style="background-color: yellow;">${match}</mark>`);
        }

        if (alertTerms) {
          const alertRegex = new RegExp(alertTerms, 'gi');
          newHTML = newHTML.replace(alertRegex, match => `<mark style="background-color: red; color: white;">${match}</mark>`);
        }

        if (newHTML !== text) {
          span.innerHTML = newHTML;
        }
      });
    });
  }, [searchTerm, alertTerms]);

  const handleDownload = async () => {
    try {
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const newPdfDoc = await PDFDocument.create();

      for (let i = 0; i < pageNumbers.length; i++) {
        const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [pageNumbers[i] - 1]);
        newPdfDoc.addPage(copiedPage);
      }

      const pdfBytes = await newPdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `pages_${pageNumbers[0]}-${pageNumbers[pageNumbers.length - 1]}.pdf`;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    // Use a short delay to ensure the PDF content has been rendered
    const timer = setTimeout(() => highlightTerms(), 100);
    return () => clearTimeout(timer);
  }, [searchTerm, alertTerms, numPages, highlightTerms]);

  if (error) {
    return (
      <div className="text-red-500">Error loading PDF: {error.message}</div>
    );
  }

  return (
    <div ref={containerRef} className="w-full">
      <div className="flex justify-end m-2">
        <button
          onClick={handleDownload}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <Download className="mr-2" size={16} />
          <span>Download Section</span>
        </button>
      </div>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        className="border border-gray-300 rounded-md p-2 max-w-full"
      >
        {pageNumbers.map((pageNumber) => (
          <div key={pageNumber} className="relative mb-4">
            <Page
              pageNumber={pageNumber}
              width={pageWidth}
              className="shadow-md"
              renderTextLayer={true}
              renderAnnotationLayer={true}
              onRenderSuccess={highlightTerms}
            />
            <div className="absolute bottom-2 right-2 bg-white bg-opacity-75 px-2 py-1 rounded text-sm">
              Page {pageNumber} of {numPages}
            </div>
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDFRenderer;
