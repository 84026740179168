import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      
      if (error) {
        console.error("Authentication error:", error);
        navigate("/login");
        return;
      }

      if (session) {
        // Determine the origin based on the current hostname
        const hostname = window.location.hostname;
        let origin = "unknown";
        if (hostname === "checkmycharts.com") {
          origin = "checkmycharts";
        } else if (hostname === "cases.myafterimage.com") {
          origin = "myafterimage";
        }

        // Update the user's metadata with the origin information
        const { error: updateError } = await supabase.auth.updateUser({
          data: { last_origin: origin }
        });

        if (updateError) {
          console.error("Error updating user metadata:", updateError);
        }

        navigate("/cases");
      } else {
        navigate("/login");
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;
