import React, { useState, useEffect } from "react";
import PDFRenderer from "./PDFRenderer";

const ChunkedPDFRenderer = ({ pdfUrl, chunkId, pagesPerChunk, searchTerm, alertTerms }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    if (pdfUrl) {
      import("pdfjs-dist/build/pdf").then((pdfjs) => {
        pdfjs.getDocument(pdfUrl).promise.then((pdf) => {
          setTotalPages(pdf.numPages);
        });
      });
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (totalPages !== null) {
      const startPage = chunkId * pagesPerChunk + 1;
      const endPage = Math.min(
        chunkId * pagesPerChunk + pagesPerChunk,
        totalPages,
      );
      setPageNumbers(
        Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => startPage + i,
        ),
      );
    }
  }, [chunkId, pagesPerChunk, totalPages]);

  if (!pdfUrl || pageNumbers.length === 0) {
    return null;
  }

  return <PDFRenderer url={pdfUrl} pageNumbers={pageNumbers} searchTerm={searchTerm} alertTerms={alertTerms} />;
};

export default ChunkedPDFRenderer;
